import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const TMF_STUDY_ADMIN_LANG: {
  // NOUN
  INDEX_NOUN: MvnCodeFE;
  NUMBER_NOUN: MvnCodeFE;
  ORGANIZATION_NOUN: MvnCodeFE;
  PROTOCOL_NUMBER_NOUN: MvnCodeFE;
  TRIAL_NOUN: MvnCodeFE;
  STUDY_NOUN: MvnCodeFE;
  STUDY_INFO_NOUN: MvnCodeFE;
  SITE_NOUN: MvnCodeFE;
  SITE_INFO_NOUN: MvnCodeFE;
  COUNTRY_NOUN: MvnCodeFE;
  NAME_NOUN: MvnCodeFE;
  CODE_NOUN: MvnCodeFE;
  REQUESTED_AT_WITH_TIME_NOUN: MvnCodeFE;
  CREATED_AT_WITH_TIME_NOUN: MvnCodeFE;
  CREATED_AT_NOUN: MvnCodeFE;
  CREATED_BY_NOUN: MvnCodeFE;
  EXPIRED_AT_WITH_TIME_NOUN: MvnCodeFE;
  AUDIT_TRAIL_NOUN: MvnCodeFE;
  WORKFLOW_HISTORY_NOUN: MvnCodeFE;
  WORKFLOW_HISTORY_RECIVER_LIST_NOUN: MvnCodeFE;
  USER_NOUN: MvnCodeFE;
  BLINDING_NOUN: MvnCodeFE;
  EMAIL_NOUN: MvnCodeFE;
  ROLE_NOUN: MvnCodeFE;
  DESCRIPTION_NOUN: MvnCodeFE;
  PRIVILEGE_NOUN: MvnCodeFE;
  TEMPLATE_NOUN: MvnCodeFE;
  MILESTONE_NOUN: MvnCodeFE;
  ZONE_NOUN: MvnCodeFE;
  SECTION_NOUN: MvnCodeFE;
  ARTIFACT_NOUN: MvnCodeFE;
  TMF_STRUCTURE_NOUN: MvnCodeFE;
  ZONE_LIST_NOUN: MvnCodeFE;
  IDENTIFIER_NOUN: MvnCodeFE;
  GROUP_NOUN: MvnCodeFE;
  INVITE_STATUS_NOUN: MvnCodeFE;
  TMF_ROLE_NOUN: MvnCodeFE;
  RESEND_NOUN: MvnCodeFE;
  IS_MANDATORY_NOUN: MvnCodeFE;
  MANDATORY_NOUN: MvnCodeFE;
  OPTIONAL_NOUN: MvnCodeFE;
  ICH_CODE_NOUN: MvnCodeFE;
  UNIQUE_ID_NOUN: MvnCodeFE;
  TMF_SIDE_NOUN: MvnCodeFE;
  SPONSOR_NOUN: MvnCodeFE;
  INVESTIGATOR_NOUN: MvnCodeFE;
  TMF_LEVEL_NOUN: MvnCodeFE;
  STUDY_MILESTONE_NOUN: MvnCodeFE;
  COUNTRY_MILESTONE_NOUN: MvnCodeFE;
  SITE_MILESTONE_NOUN: MvnCodeFE;
  DATE_CONVENTION_NOUN: MvnCodeFE;
  SUB_ARTIFACT_NOUN: MvnCodeFE;
  SUB_ARTIFACT_TYPE_NOUN: MvnCodeFE;
  UPDATED_LIST_NOUN: MvnCodeFE;
  DOCUMENT_ATTRIBUTE_NOUN: MvnCodeFE;
  DOCUMENT_INFO_NOUN: MvnCodeFE;
  DOCUMENT_QC_REQUEST_NOUN: MvnCodeFE;
  DOCUMENT_QC_APPROVE_NOUN: MvnCodeFE;
  DOCUMENT_QC_REJECT_NOUN: MvnCodeFE;
  UPLOAD_FILE_NOUN: MvnCodeFE;
  TMF_LOCATION_NOUN: MvnCodeFE;
  LEVEL_NOUN: MvnCodeFE;
  ALL_NOUN: MvnCodeFE;
  TOTAL_FINALIZED_DOCUMENTS_RATE_NOUN: MvnCodeFE;
  FINALIZED_NOUN: MvnCodeFE;
  UNFINALIZED_NOUN: MvnCodeFE;
  CHARGED_DOCUMENTS_STATUS_NOUN: MvnCodeFE;
  TOTAL_DOCUMENTS_STATUS_NOUN: MvnCodeFE;
  TOTAL_NOUN: MvnCodeFE;
  MORE_NOUN: MvnCodeFE;
  DOCUMENT_NOUN: MvnCodeFE;
  EXTENSION_NOUN: MvnCodeFE;
  STATUS_NOUN: MvnCodeFE;
  UPDATED_BY_NOUN: MvnCodeFE;
  UPDATED_AT_NOUN: MvnCodeFE;
  LAST_UPDATED_AT_NOUN: MvnCodeFE;
  LOCATION_NOUN: MvnCodeFE;
  COMMENT_NOUN: MvnCodeFE;
  EDITED_NOUN: MvnCodeFE;
  VERSION_NOUN: MvnCodeFE;
  CHANGE_DETAIL_NOUN: MvnCodeFE;
  BEFORE_NOUN: MvnCodeFE;
  AFTER_NOUN: MvnCodeFE;
  ACTION_NOUN: MvnCodeFE;
  CONTENT_NOUN: MvnCodeFE;
  ACTANT_NOUN: MvnCodeFE;
  CREATED_AT_AUDIT_TRAIL_NOUN: MvnCodeFE;
  UNPUBLISHED_DOCUMENT_NOUN: MvnCodeFE;
  PUBLISHED_DOCUMENT_NOUN: MvnCodeFE;
  COLUMN_SETTING_NOUN: MvnCodeFE;
  REASON_NOUN: MvnCodeFE;
  NEWEST_SORT_NOUN: MvnCodeFE;
  OLDEST_SORT_NOUN: MvnCodeFE;
  TITLE_NOUN: MvnCodeFE;
  CREATE_DOCUMENT_TYPE_NOUN: MvnCodeFE;
  EMS_NOUN: MvnCodeFE;
  TMF_LEVEL_DOCUMENT_NOUN: MvnCodeFE;
  COUNTRY_LEVEL_DOCUMENT_NOUN: MvnCodeFE;
  SITE_LEVEL_DOCUMENT_NOUN: MvnCodeFE;
  INCLUDE_ITEM_NOUN: MvnCodeFE;
  FILTERING_GROUP_NOUN: MvnCodeFE;
  CHECKED_NOUN: MvnCodeFE;
  UNCHECKED_NOUN: MvnCodeFE;
  TYPE_NOUN: MvnCodeFE;
  FAILED_NOUN: MvnCodeFE;
  EXPIRED_NOUN: MvnCodeFE;
  GENERATING_NOUN: MvnCodeFE;
  REVIEWER_NOUN: MvnCodeFE;
  START_DATE_NOUN: MvnCodeFE;
  REVIEW_DUE_DATE_NOUN: MvnCodeFE;
  REVIEW_REJECT_REASON_NOUN: MvnCodeFE;
  REVIEW_COMMENT_NOUN: MvnCodeFE;
  UPLOADER_NOUN: MvnCodeFE;
  SENDER_NOUN: MvnCodeFE;
  RECEIVER_NOUN: MvnCodeFE;
  EVENT_DATE_NOUN: MvnCodeFE;
  WORFKFLOW_HISTORY_COMMENT_NOUN: MvnCodeFE;

  // VERB
  CREATE_VERB: MvnCodeFE;
  CANCEL_VERB: MvnCodeFE;
  SAVE_VERB: MvnCodeFE;
  DELETE_VERB: MvnCodeFE;
  ADD_VERB: MvnCodeFE;
  FINALIZE_VERB: MvnCodeFE;
  LOCK_VERB: MvnCodeFE;
  UNLOCK_VERB: MvnCodeFE;
  IMPORT_VERB: MvnCodeFE;
  INIT_VERB: MvnCodeFE;
  VIEW_DELETED_VERB: MvnCodeFE;
  CONFIRM_VERB: MvnCodeFE;
  CLOSE_VERB: MvnCodeFE;
  PUBLISH_VERB: MvnCodeFE;
  BACK_VERB: MvnCodeFE;
  UPLOAD_VERB: MvnCodeFE;
  DOWNLOAD_VERB: MvnCodeFE;
  SAMPLE_DOWNLOAD_VERB: MvnCodeFE;
  SEARCH_VERB: MvnCodeFE;
  REQUEST_VERB: MvnCodeFE;
  DOCUMENT_REQUEST_QC_VERB: MvnCodeFE;
  CANCEL_DOCUMENT_QC_VERB: MvnCodeFE;
  APPROVE_VERB: MvnCodeFE;
  REJECT_VERB: MvnCodeFE;
  ENTER_VERB: MvnCodeFE;
  UPDATE_VERB: MvnCodeFE;
  READ_VERB: MvnCodeFE;
  VIEW_VERB: MvnCodeFE;
  DETAIL_VERB: MvnCodeFE;
  LEAVE_VERB: MvnCodeFE;
  STAY_VERB: MvnCodeFE;
  INVITE_VERB: MvnCodeFE;
  INVITE_TEMPLATE_VERB: MvnCodeFE;
  INVITE_ACCEPT_VERB: MvnCodeFE;
  INVITE_REJECT_VERB: MvnCodeFE;
  EDIT_VERB: MvnCodeFE;

  // EMPTY_VIEW
  TITLE_EMPTY_VIEW: MvnCodeFE;
  TITLE_NO_CHANGE_VIEW: MvnCodeFE;
  NO_STUDY_CREATE_EMPTY_VIEW: MvnCodeFE;
  NO_STUDY_SET_UP_EMPTY_VIEW: MvnCodeFE;
  NO_USER_EMPTY_VIEW: MvnCodeFE;
  NO_ROLE_EMPTY_VIEW: MvnCodeFE;
  NO_TEMPLATE_ADD_EMPTY_VIEW: MvnCodeFE;
  NO_TEMPLATE_LOAD_EMPTY_VIEW: MvnCodeFE;
  NO_TMF_STRUCTURE_EMPTY_VIEW: MvnCodeFE;
  NO_COMMENT_EMPTY_VIEW: MvnCodeFE;
  NO_DOCUMENT_EMPTY_VIEW: MvnCodeFE;
  FILE_UNSUPPORTED_FORMAT_EMPTY_VIEW: MvnCodeFE;
  FILE_LOADING_EMPTY_VIEW: MvnCodeFE;
  FILE_CONVERT_FAIL_EMPTY_VIEW: MvnCodeFE;
  FILE_EXPORT_SUPPORT_EMPTY_VIEW: MvnCodeFE;
  EXPORT_SUPPORT_EMPTY_VIEW: MvnCodeFE;
  NO_SELECTED_ARTIFACT_EMPTY_VIEW: MvnCodeFE;
  NO_SELECTED_MILESTONE_EMPTY_VIEW: MvnCodeFE;
  DW_HISTORY_EMPTY_VIEW: MvnCodeFE;

  // VALIDATION
  REQUIRED_FIELD_EMPTY_VALIDATION: MvnCodeFE;
  SAVE_FAIL_BY_REQUIRED_FIELD_EMPTY_VALIDATION: MvnCodeFE;
  INCORRECT_FORMAT_VALIDATION: MvnCodeFE;
  INCORRECT_FILE_VALIDATION: MvnCodeFE;
  LEAST_ONE_VALIDATION: MvnCodeFE;
  INVALID_DOCUMENT_VALIDATION: MvnCodeFE;
  INVALID_DOCUMENT_PROPERTIES_VALIDATION: MvnCodeFE;
  INVALID_DOCUMENT_PROPERTY_ZONE_VALIDATION: MvnCodeFE;
  INVALID_DOCUMENT_PROPERTY_SECTION_VALIDATION: MvnCodeFE;
  INVALID_DOCUMENT_PROPERTY_ARTIFACT_VALIDATION: MvnCodeFE;
  INVALID_DOCUMENT_PROPERTY_SUB_ARTIFACT_VALIDATION: MvnCodeFE;
  INVALID_DOCUMENT_PROPERTY_COUNTRY_VALIDATION: MvnCodeFE;
  INVALID_DOCUMENT_PROPERTY_SITE_VALIDATION: MvnCodeFE;
  INVALID_MILESTONE_VALIDATION: MvnCodeFE;
  INVALID_STRUCTURE_VALIDATION: MvnCodeFE;
  RANGE_OF_CHARACTERS_VALIDATION: MvnCodeFE;
  DUPLICATED_ITEM_VALIDATION: MvnCodeFE;
  MAX_LIMIT_EXCEEDED_VALIDATION: MvnCodeFE;

  // PRIVILEGE_DESCRIPTION
  TMF_READ_PRIVILEGE_DESCRIPTION: MvnCodeFE;
  DOCUMENT_WRITE_PRIVILEGE_DESCRIPTION: MvnCodeFE;
  DOCUMENT_QC_REQUEST_PRIVILEGE_DESCRIPTION: MvnCodeFE;
  DOCUMENT_APPROVE_PRIVILEGE_DESCRIPTION: MvnCodeFE;
  DOCUMENT_LOCK_PRIVILEGE_DESCRIPTION: MvnCodeFE;
  DOCUMENT_COMMENT_PRIVILEGE_DESCRIPTION: MvnCodeFE;
  MILESTONE_READ_PRIVILEGE_DESCRIPTION: MvnCodeFE;
  MILESTONE_MANAGE_PRIVILEGE_DESCRIPTION: MvnCodeFE;
  ADMIN_READ_PRIVILEGE_DESCRIPTION: MvnCodeFE;
  ADMIN_MANAGE_PRIVILEGE_DESCRIPTION: MvnCodeFE;
  AUDIT_TRAIL_DOWNLOAD_PRIVILEGE_DESCRIPTION: MvnCodeFE;

  // TITLE
  CREATE_STUDY_TITLE: MvnCodeFE;
  ADD_COUNTRY_TITLE: MvnCodeFE;
  ADD_USER_TITLE: MvnCodeFE;
  ADD_ROLE_TITLE: MvnCodeFE;
  ADD_MILESTONE_TITLE: MvnCodeFE;
  ADD_ZONE_TITLE: MvnCodeFE;
  ADD_SECTION_TITLE: MvnCodeFE;
  ADD_ARTIFACT_TITLE: MvnCodeFE;
  ADD_SUB_ARTIFACT_TITLE: MvnCodeFE;
  ADD_TEMPLATE_TITLE: MvnCodeFE;
  SAVE_MILESTONE_TITLE: MvnCodeFE;
  SAVE_ZONE_LIST_TITLE: MvnCodeFE;
  PUBLISH_TMF_STRUCTURE_TITLE: MvnCodeFE;
  DELETE_MILESTONE_TITLE: MvnCodeFE;
  DELETE_ARTIFACT_TITLE: MvnCodeFE;
  DELETE_SECTION_TITLE: MvnCodeFE;
  DELETE_ZONE_TITLE: MvnCodeFE;
  DELETE_DOCUMENT_TITLE: MvnCodeFE;
  EDIT_NAME_TITLE: MvnCodeFE;
  DOWNLOAD_TEMPLATE_TITLE: MvnCodeFE;
  DOWNLOAD_DOCUMENT_LIST_BUTTON_TITLE: MvnCodeFE;
  DOWNLOAD_DOCUMENT_LIST_DIALOG_TITLE: MvnCodeFE;
  UPLOAD_FILE_TITLE: MvnCodeFE;
  RETRY_CONVERT_TITLE: MvnCodeFE;
  INVITATION_COUNT_TITLE: MvnCodeFE;
  DELETE_TEMPLATE_TITLE: MvnCodeFE;
  EDIT_CANCLE_TITLE: MvnCodeFE;
  EDIT_COMPLETE_TITLE: MvnCodeFE;
  STRUCTURE_INIT_TITLE: MvnCodeFE;
  DELETE_COMMENT_TITLE: MvnCodeFE;
  CREATE_ARCHIVER_TITLE: MvnCodeFE;

  // PLACEHOLDER
  SELECT_ORGANIZATION_PLACEHOLDER: MvnCodeFE;
  SELECT_TRIAL_PLACEHOLDER: MvnCodeFE;
  SELECT_COUNTRY_PLACEHOLDER: MvnCodeFE;
  SELECT_ROLE_PLACEHOLDER: MvnCodeFE;
  SELECT_SITE_PLACEHOLDER: MvnCodeFE;
  SELECT_MILESTONE_GROUP_PLACEHOLDER: MvnCodeFE;
  SELECT_MILESTONE_PLACEHOLDER: MvnCodeFE;
  SELECT_STUDY_MILESTONE_PLACEHOLDER: MvnCodeFE;
  SELECT_COUNTRY_MILESTONE_PLACEHOLDER: MvnCodeFE;
  SELECT_SITE_MILESTONE_PLACEHOLDER: MvnCodeFE;
  SELECT_DATE_CONVENTION_PLACEHOLDER: MvnCodeFE;
  SELECT_ZONE_PLACEHOLDER: MvnCodeFE;
  SELECT_SECTION_PLACEHOLDER: MvnCodeFE;
  SELECT_ARTIFACT_PLACEHOLDER: MvnCodeFE;
  SELECT_LEVEL_PLACEHOLDER: MvnCodeFE;
  SELECT_SUB_ARTIFACT_PLACEHOLDER: MvnCodeFE;
  SELECT_TMF_LEVEL_DOCUMENT_PLACEHOLDER: MvnCodeFE;
  SELECT_COUNTRY_LEVEL_DOCUMENT_PLACEHOLDER: MvnCodeFE;
  SELECT_SITE_LEVEL_DOCUMENT_PLACEHOLDER: MvnCodeFE;
  ENTER_STUDY_NAME_PLACEHOLDER: MvnCodeFE;
  ENTER_PROTOCOL_NUMBER_PLACEHOLDER: MvnCodeFE;
  ENTER_USER_NAME_PLACEHOLDER: MvnCodeFE;
  ENTER_EMAIL_PLACEHOLDER: MvnCodeFE;
  ENTER_ROLE_NAME_PLACEHOLDER: MvnCodeFE;
  ENTER_ROLE_DESCRIPTION_PLACEHOLDER: MvnCodeFE;
  ENTER_ID_PLACEHOLDER: MvnCodeFE;
  ENTER_STUDY_DESCRIPTION_PLACEHOLDER: MvnCodeFE;
  ENTER_COUNTRY_DESCRIPTION_PLACEHOLDER: MvnCodeFE;
  ENTER_SITE_DESCRIPTION_PLACEHOLDER: MvnCodeFE;
  ENTER_DESCRIPTION_PLACEHOLDER: MvnCodeFE;
  ENTER_ICH_CODE_PLACEHOLDER: MvnCodeFE;
  ENTER_UNIQUE_ID_PLACEHOLDER: MvnCodeFE;
  ENTER_NAME_PLACEHOLDER: MvnCodeFE;
  ENTER_DOCUMENT_NAME_PLACEHOLDER: MvnCodeFE;
  ENTER_DOCUMENT_DELETED_REASON_PLACEHOLDER: MvnCodeFE;
  ENTER_DOCUMENT_FILE_UPLOAD_PLACEHOLDER: MvnCodeFE;
  ENTER_COMMENT_PLACEHOLDER: MvnCodeFE;
  ENTER_SITE_NAME_PLACEHOLDER: MvnCodeFE;
  ENTER_SITE_CODE_PLACEHOLDER: MvnCodeFE;
  ENTER_TEMPLATE_TITLE_PLACEHOLDER: MvnCodeFE;
  ENTER_MILESTONE_NAME_PLACEHOLDER: MvnCodeFE;
  SELECT_REVIEWER_NAME_PLACEHOLDER: MvnCodeFE;
  SELECT_START_DATE_PLACEHOLDER: MvnCodeFE;
  SELECT_REVIEW_DUE_DATE_PLACEHOLDER: MvnCodeFE;
  SELECT_REJECT_QC_REASON_PLACEHOLDER: MvnCodeFE;
  ENTER_REVIEW_COMMENT_PLACEHOLDER: MvnCodeFE;

  // HELP_TEXT
  ICH_CODE_FORMAT_HELPER_TEXT: MvnCodeFE;
  PUBLISH_TMF_STRUCTURE_HELPER_TEXT: MvnCodeFE;
  SELECT_DOCUMENT_HELPER_TEXT: MvnCodeFE;
  DRAG_AND_DROP_DOCUMENT_HELPER_TEXT: MvnCodeFE;
  DRAG_AND_DROP_DOCUMENT_DESCRIPTION_HELPER_TEXT: MvnCodeFE;
  FILE_SIZE_HELPER_TEXT: MvnCodeFE;
  FILE_FORMAT_HELPER_TEXT: MvnCodeFE;
  FILE_SIZE_FORMAT_HELPER_TEXT: MvnCodeFE;
  FILE_UPLOAD_HELPER_TEXT: MvnCodeFE;
  USED_MILESTONE_HELPER_TEXT: MvnCodeFE;
  TMF_STRUCTURE_PUBLISH_HELPER_TEXT: MvnCodeFE;

  // Prompt
  LEAVE_FORM_PROMPT: MvnCodeFE;
  LEAVE_PAGE_PROMPT: MvnCodeFE;
  CLOSE_UPLOAD_PROGRESS_PROMPT: MvnCodeFE;
  DELETE_MILESTONE_PROMPT: MvnCodeFE;
  DELETE_TEMPLATE_PROMPT: MvnCodeFE;
  DELETE_PROMPT: MvnCodeFE;
  EDIT_CANCLE_PROMPT: MvnCodeFE;
  INIT_STRUCTURE_PROMPT: MvnCodeFE;
  DELETE_COMMENT_PROMPT: MvnCodeFE;
  DOWNLOAD_DOCUMENT_LIST_PROMPT: MvnCodeFE;
  APPROVE_DOCUMENT_QC_PROMPT: MvnCodeFE;
  REJECT_DOCUMENT_QC_PROMPT: MvnCodeFE;

  // TOAST
  ORGANIZATION_LOAD_SUCCESS_TOAST: MvnCodeFE;
  COUNTRY_LOAD_FAIL_TOAST: MvnCodeFE;
  STUDY_LIST_LOAD_FAIL_TOAST: MvnCodeFE;
  STUDY_INACCESSIBLE_TOAST: MvnCodeFE;
  STUDY_LOAD_FAIL_TOAST: MvnCodeFE;
  STUDY_CREATE_SUCCESS_TOAST: MvnCodeFE;
  STUDY_CREATE_FAIL_TOAST: MvnCodeFE;
  STUDY_UPDATE_SUCCESS_TOAST: MvnCodeFE;
  STUDY_UPDATE_FAIL_TOAST: MvnCodeFE;
  STUDY_LOCK_SUCCESS_TOAST: MvnCodeFE;
  STUDY_LOCK_FAIL_TOAST: MvnCodeFE;
  STUDY_UNLOCK_SUCCESS_TOAST: MvnCodeFE;
  STUDY_UNLOCK_FAIL_TOAST: MvnCodeFE;
  USER_LOAD_FAIL_TOAST: MvnCodeFE;
  USER_UPDATE_SUCCESS_TOAST: MvnCodeFE;
  USER_UPDATE_FAIL_TOAST: MvnCodeFE;
  INVITE_USER_SUCCESS_TOAST: MvnCodeFE;
  ROLE_LOAD_FAIL_TOAST: MvnCodeFE;
  ROLE_UPDATE_SUCCESS_TOAST: MvnCodeFE;
  ROLE_UPDATE_FAIL_TOAST: MvnCodeFE;
  PRIVILEGE_LOAD_FAIL_TOAST: MvnCodeFE;
  PRIVILEGE_UPDATE_SUCCESS_TOAST: MvnCodeFE;
  PRIVILEGE_UPDATE_FAIL_TOAST: MvnCodeFE;
  TEMPLATE_LOAD_FAIL_TOAST: MvnCodeFE;
  TEMPLATE_CREATE_SUCCESS_TOAST: MvnCodeFE;
  TEMPLATE_CREATE_FAIL_TOAST: MvnCodeFE;
  TEMPLATE_DELETE_SUCCESS_TOAST: MvnCodeFE;
  TEMPLATE_DELETE_FAIL_TOAST: MvnCodeFE;
  MILESTONE_LOAD_FAIL_TOAST: MvnCodeFE;
  MILESTONE_SAVE_SUCCESS_TOAST: MvnCodeFE;
  MILESTONE_SAVE_FAIL_TOAST: MvnCodeFE;
  ZONE_LIST_LOAD_FAIL_TOAST: MvnCodeFE;
  ZONE_LIST_SAVE_SUCCESS_TOAST: MvnCodeFE;
  ZONE_LIST_SAVE_FAIL_TOAST: MvnCodeFE;
  TMF_STRUCTURE_PUBLISH_SUCCESS_TOAST: MvnCodeFE;
  TMF_STRUCTURE_PUBLISH_FAIL_TOAST: MvnCodeFE;
  DOCUMENT_LIST_DOWNLOAD_SUCCESS_TOAST: MvnCodeFE;
  DOCUMENT_LIST_DOWNLOAD_FAIL_TOAST: MvnCodeFE;
  DOCUMENT_UPLOAD_FAIL_TOAST: MvnCodeFE;
  DOCUMENT_UPLOAD_SUCCESS_TOAST: MvnCodeFE;
  DOCUMENT_CREATE_SUCCESS_TOAST: MvnCodeFE;
  DOCUMENT_CREATE_FAIL_TOAST: MvnCodeFE;
  DOCUMENT_LIST_LOAD_FAIL_TOAST: MvnCodeFE;
  DOCUMENT_LOAD_FAIL_TOAST: MvnCodeFE;
  DOCUMENT_FILE_LOAD_FAIL_TOAST: MvnCodeFE;
  DOCUMENT_DOWNLOAD_SUCCESS_TOAST: MvnCodeFE;
  DOCUMENT_DOWNLOAD_FAIL_TOAST: MvnCodeFE;
  DOCUMENT_SAVE_SUCCESS_TOAST: MvnCodeFE;
  DOCUMENT_SAVE_FAIL_TOAST: MvnCodeFE;
  DOCUMENT_APPROVE_SUCCESS_TOAST: MvnCodeFE;
  DOCUMENT_APPROVE_FAIL_TOAST: MvnCodeFE;
  DOCUMENT_QC_REQUEST_SUCCESS_TOAST: MvnCodeFE;
  DOCUMENT_QC_REQUEST_FAIL_TOAST: MvnCodeFE;
  DOCUMENT_REJECT_SUCCESS_TOAST: MvnCodeFE;
  DOCUMENT_REJECT_FAIL_TOAST: MvnCodeFE;
  DOCUMENT_FINALIZE_SUCCESS_TOAST: MvnCodeFE;
  DOCUMENT_FINALIZE_FAIL_TOAST: MvnCodeFE;
  DOCUMENT_LOCK_SUCCESS_TOAST: MvnCodeFE;
  DOCUMENT_LOCK_FAIL_TOAST: MvnCodeFE;
  DOCUMENT_UNLOCK_SUCCESS_TOAST: MvnCodeFE;
  DOCUMENT_UNLOCK_FAIL_TOAST: MvnCodeFE;
  DOCUMENT_COMMENT_CREATE_SUCCESS_TOAST: MvnCodeFE;
  DOCUMENT_COMMENT_LOAD_FAIL_TOAST: MvnCodeFE;
  DOCUMENT_COMMENT_CREATE_FAIL_TOAST: MvnCodeFE;
  DOCUMENT_COMMENT_UPDATE_SUCCESS_TOAST: MvnCodeFE;
  DOCUMENT_COMMENT_UPDATE_FAIL_TOAST: MvnCodeFE;
  DOCUMENT_COMMENT_DELETE_SUCCESS_TOAST: MvnCodeFE;
  DOCUMENT_COMMENT_DELETE_FAIL_TOAST: MvnCodeFE;
  PUBLISHED_DOCUMENT_LOAD_FAIL_TOAST: MvnCodeFE;
  DASHBOARD_LOAD_FAIL_TOAST: MvnCodeFE;
  DOCUMENT_OWNER_LOAD_FAIL_TOAST: MvnCodeFE;
  DOCUMENT_REVIEWABLE_USER_LOAD_FAIL_TOAST: MvnCodeFE;
  DOCUMENT_DELETE_SUCCESS_TOAST: MvnCodeFE;
  DOCUMENT_DELETE_FAIL_TOAST: MvnCodeFE;
  DOCUMENT_ALL_UPLOAD_SUCCESS_TOAST: MvnCodeFE;
  DOCUMENT_ALL_UPLOAD_FAIL_TOAST: MvnCodeFE;
  SITE_LOAD_FAIL_TOAST: MvnCodeFE;
  RESEND_INVITATION_SUCCESS_TOAST: MvnCodeFE;
  RESEND_INVITATION_FAIL_TOAST: MvnCodeFE;
  STUDY_INVITATION_LOAD_FAIL_TOAST: MvnCodeFE;
  INVITATION_ACCEPT_SUCCESS_TOAST: MvnCodeFE;
  INVITATION_ACCEPT_FAIL_TOAST: MvnCodeFE;
  INVITATION_REJECT_SUCCESS_TOAST: MvnCodeFE;
  INVITATION_REJECT_FAIL_TOAST: MvnCodeFE;
  STRUCTURE_CREATE_SUCCESS_TOAST: MvnCodeFE;
  STRUCTURE_CREATE_FAIL_TOAST: MvnCodeFE;
  STRUCTURE_INIT_SUCCESS_TOAST: MvnCodeFE;
  STRUCTURE_INIT_FAIL_TOAST: MvnCodeFE;
  STRUCTURE_LOAD_FAIL_TOAST: MvnCodeFE;
  STRUCTURE_SAVE_SUCCESS_TOAST: MvnCodeFE;
  STRUCTURE_SAVE_FAIL_TOAST: MvnCodeFE;
  AUDIT_TRAIL_LOAD_FAIL_TOAST: MvnCodeFE;
  WORKFLOW_HISTORY_LOAD_FAIL_TOAST: MvnCodeFE;
  DW_HISTORY_METADATA_LOAD_FAIL_TOAST: MvnCodeFE;
  DW_HISTORY_LOAD_FAIL_TOAST: MvnCodeFE;
  DW_HISTORY_CREATE_SUCCESS_TOAST: MvnCodeFE;
  DW_HISTORY_CREATE_FAIL_TOAST: MvnCodeFE;
  DW_HISTORY_DOWNLOAD_SUCCESS_TOAST: MvnCodeFE;
  DW_HISTORY_DOWNLOAD_FAIL_TOAST: MvnCodeFE;
  PDF_RETRY_CONVERT_REQUEST_SUCCESS_TOAST: MvnCodeFE;
  PDF_RETRY_CONVERT_REQUEST_FAIL_TOAST: MvnCodeFE;

  // TOOLTIP
  DOCUMENT_INVALID_TOOLTIP_TITLE: MvnCodeFE;
} = {
  // NOUN
  INDEX_NOUN: {
    ko: "Index",
    en: "Index",
  },
  NUMBER_NOUN: {
    ko: "번호",
    en: "No.",
  },
  ORGANIZATION_NOUN: {
    ko: "조직",
    en: "Organization",
  },
  PROTOCOL_NUMBER_NOUN: {
    ko: "프로토콜 번호",
    en: "Protocol No.",
  },
  TRIAL_NOUN: {
    ko: "스터디",
    en: "Trial",
  },
  STUDY_NOUN: {
    ko: "스터디",
    en: "Study",
  },
  STUDY_INFO_NOUN: {
    ko: "스터디 정보",
    en: "Study",
  },
  SITE_NOUN: {
    ko: "실시기관",
    en: "Site",
  },
  SITE_INFO_NOUN: {
    ko: "실시기관 정보",
    en: "Site",
  },
  COUNTRY_NOUN: {
    ko: "국가",
    en: "Country",
  },
  NAME_NOUN: {
    ko: "이름",
    en: "Name",
  },
  CODE_NOUN: {
    ko: "코드",
    en: "Code",
  },
  REQUESTED_AT_WITH_TIME_NOUN: {
    ko: "요청일시",
    en: "Requested At",
  },
  CREATED_AT_WITH_TIME_NOUN: {
    ko: "생성일시",
    en: "Created At",
  },
  CREATED_AT_NOUN: {
    ko: "생성일",
    en: "Created At",
  },
  CREATED_BY_NOUN: {
    ko: "생성자",
    en: "Created By",
  },
  EXPIRED_AT_WITH_TIME_NOUN: {
    ko: "만료일시",
    en: "Expired At",
  },
  AUDIT_TRAIL_NOUN: {
    ko: "Audit Trail",
    en: "Audit Trail",
  },
  WORKFLOW_HISTORY_NOUN: {
    ko: "Workflow History",
    en: "Workflow History",
  },
  WORKFLOW_HISTORY_RECIVER_LIST_NOUN: {
    ko: "Receiver List",
    en: "Receiver List",
  },
  USER_NOUN: {
    ko: "유저",
    en: "User",
  },
  BLINDING_NOUN: {
    ko: "눈가림",
    en: "Blinding",
  },
  EMAIL_NOUN: {
    ko: "이메일",
    en: "Email",
  },
  ROLE_NOUN: {
    ko: "역할",
    en: "Role",
  },
  DESCRIPTION_NOUN: {
    ko: "설명",
    en: "Description",
  },
  PRIVILEGE_NOUN: {
    ko: "권한",
    en: "Privilege",
  },
  TEMPLATE_NOUN: {
    ko: "템플릿",
    en: "Template",
  },
  MILESTONE_NOUN: {
    ko: "마일스톤",
    en: "Milestone",
  },
  ZONE_NOUN: {
    ko: "Zone",
    en: "Zone",
  },
  SECTION_NOUN: {
    ko: "Section",
    en: "Section",
  },
  ARTIFACT_NOUN: {
    ko: "Artifact",
    en: "Artifact",
  },
  TMF_STRUCTURE_NOUN: {
    ko: "TMF 구조",
    en: "TMF Structure",
  },
  ZONE_LIST_NOUN: {
    ko: "Zone 리스트",
    en: "Zone List",
  },
  IDENTIFIER_NOUN: {
    ko: "ID",
    en: "ID",
  },
  GROUP_NOUN: {
    ko: "그룹",
    en: "Group",
  },
  INVITE_STATUS_NOUN: {
    ko: "초대 상태",
    en: "Invite Status",
  },
  TMF_ROLE_NOUN: {
    ko: "TMF 역할",
    en: "TMF Role",
  },
  RESEND_NOUN: {
    ko: "메일 재발송",
    en: "Resend",
  },
  IS_MANDATORY_NOUN: {
    ko: "필수 여부",
    en: "Is Mandatory",
  },
  MANDATORY_NOUN: {
    ko: "필수",
    en: "Mandatory",
  },
  OPTIONAL_NOUN: {
    ko: "선택",
    en: "Optional",
  },
  ICH_CODE_NOUN: {
    ko: "ICH 코드",
    en: "ICH Code",
  },
  UNIQUE_ID_NOUN: {
    ko: "Unique ID",
    en: "Unique ID",
  },
  TMF_SIDE_NOUN: {
    ko: "TMF Side",
    en: "TMF Side",
  },
  SPONSOR_NOUN: {
    ko: "스폰서",
    en: "Sponsor",
  },
  INVESTIGATOR_NOUN: {
    ko: "연구자",
    en: "Investigator",
  },
  TMF_LEVEL_NOUN: {
    ko: "TMF Level",
    en: "TMF Level",
  },
  STUDY_MILESTONE_NOUN: {
    ko: "스터디 마일스톤",
    en: "Study Milestone",
  },
  COUNTRY_MILESTONE_NOUN: {
    ko: "국가 마일스톤",
    en: "Country Milestone",
  },
  SITE_MILESTONE_NOUN: {
    ko: "실시기관 마일스톤",
    en: "Site Milestone",
  },
  DATE_CONVENTION_NOUN: {
    ko: "날짜 규칙",
    en: "Date Convention",
  },
  SUB_ARTIFACT_NOUN: {
    ko: "문서 종류",
    en: "Document Type",
  },
  SUB_ARTIFACT_TYPE_NOUN: {
    ko: "종류",
    en: "Type",
  },
  UPDATED_LIST_NOUN: {
    ko: "수정 목록",
    en: "Updated List",
  },
  DOCUMENT_ATTRIBUTE_NOUN: {
    ko: "문서 속성",
    en: "Document Attributes",
  },
  DOCUMENT_INFO_NOUN: {
    ko: "문서 정보",
    en: "Document Info",
  },
  DOCUMENT_QC_REQUEST_NOUN: {
    ko: "QC Request",
    en: "QC Request",
  },
  DOCUMENT_QC_APPROVE_NOUN: {
    ko: "QC Approve",
    en: "QC Approve",
  },
  DOCUMENT_QC_REJECT_NOUN: {
    ko: "QC Reject",
    en: "QC Reject",
  },
  UPLOAD_FILE_NOUN: {
    ko: "업로드 파일",
    en: "Upload File",
  },
  TMF_LOCATION_NOUN: {
    ko: "TMF 위치",
    en: "TMF Location",
  },
  LEVEL_NOUN: {
    ko: "레벨",
    en: "Level",
  },
  ALL_NOUN: {
    ko: "전체",
    en: "All",
  },
  TOTAL_FINALIZED_DOCUMENTS_RATE_NOUN: {
    ko: "전체 문서 완료율",
    en: "Total Finalized Documents Rate",
  },
  FINALIZED_NOUN: {
    ko: "완료",
    en: "Finalized",
  },
  UNFINALIZED_NOUN: {
    ko: "미완료",
    en: "Unfinalized",
  },
  CHARGED_DOCUMENTS_STATUS_NOUN: {
    ko: "담당 문서 상태",
    en: "Charged Documents Status",
  },
  TOTAL_DOCUMENTS_STATUS_NOUN: {
    ko: "전체 문서 상태",
    en: "Total Documents Status",
  },
  TOTAL_NOUN: {
    ko: "총",
    en: "Total",
  },
  MORE_NOUN: {
    ko: "더보기",
    en: "More",
  },
  DOCUMENT_NOUN: {
    ko: "문서",
    en: "Document",
  },
  EXTENSION_NOUN: {
    ko: "유형",
    en: "Extension",
  },
  STATUS_NOUN: {
    ko: "상태",
    en: "Status",
  },
  UPDATED_BY_NOUN: {
    ko: "수정자",
    en: "Updated By",
  },
  UPDATED_AT_NOUN: {
    ko: "수정일",
    en: "Updated At",
  },
  LAST_UPDATED_AT_NOUN: {
    ko: "마지막 수정일",
    en: "Last Updated",
  },
  LOCATION_NOUN: {
    ko: "위치",
    en: "Location",
  },
  COMMENT_NOUN: {
    ko: "댓글",
    en: "Comment",
  },
  EDITED_NOUN: {
    ko: "수정됨",
    en: "Edited",
  },
  VERSION_NOUN: {
    ko: "버전",
    en: "Version",
  },
  CHANGE_DETAIL_NOUN: {
    ko: "변경상세",
    en: "Change Detail",
  },
  BEFORE_NOUN: {
    ko: "변경 전",
    en: "Before",
  },
  AFTER_NOUN: {
    ko: "변경 후",
    en: "After",
  },
  ACTION_NOUN: {
    ko: "행위",
    en: "Action",
  },
  CONTENT_NOUN: {
    ko: "항목",
    en: "Content",
  },
  ACTANT_NOUN: {
    ko: "수행자",
    en: "Actant",
  },
  CREATED_AT_AUDIT_TRAIL_NOUN: {
    ko: "수행일시",
    en: "Created At",
  },
  UNPUBLISHED_DOCUMENT_NOUN: {
    ko: "미발행 문서",
    en: "Unpublished Document",
  },
  PUBLISHED_DOCUMENT_NOUN: {
    ko: "발행 문서",
    en: "Published Document",
  },
  COLUMN_SETTING_NOUN: {
    ko: "컬럼 관리",
    en: "Column Setting",
  },
  REASON_NOUN: {
    ko: "사유",
    en: "Reason",
  },
  NEWEST_SORT_NOUN: {
    ko: "최근 등록순",
    en: "Newest",
  },
  OLDEST_SORT_NOUN: {
    ko: "과거 등록순",
    en: "Oldest",
  },
  TITLE_NOUN: {
    ko: "제목",
    en: "Title",
  },
  CREATE_DOCUMENT_TYPE_NOUN: {
    ko: "생성 파일 유형",
    en: "Create File Type",
  },
  EMS_NOUN: {
    ko: "Ems",
    en: "Ems",
  },
  TMF_LEVEL_DOCUMENT_NOUN: {
    ko: "Trial 레벨 문서",
    en: "Trial Level Document",
  },
  COUNTRY_LEVEL_DOCUMENT_NOUN: {
    ko: "국가 레벨 문서",
    en: "Country Level Document",
  },
  SITE_LEVEL_DOCUMENT_NOUN: {
    ko: "실시기관 레벨 문서",
    en: "Site Level Document",
  },
  INCLUDE_ITEM_NOUN: {
    ko: "포함 항목",
    en: "Include Item",
  },
  FILTERING_GROUP_NOUN: {
    ko: "필터링 그룹",
    en: "Filtering Group",
  },
  CHECKED_NOUN: {
    ko: "체크",
    en: "Checked",
  },
  UNCHECKED_NOUN: {
    ko: "미체크",
    en: "Unchecked",
  },
  TYPE_NOUN: {
    ko: "유형",
    en: "Type",
  },
  FAILED_NOUN: {
    ko: "실패",
    en: "Failed",
  },
  EXPIRED_NOUN: {
    ko: "유효기간 만료",
    en: "Expired",
  },
  GENERATING_NOUN: {
    ko: "생성중",
    en: "Generating",
  },
  REVIEWER_NOUN: {
    ko: "Reviewer",
    en: "Reviewer",
  },
  START_DATE_NOUN: {
    ko: "Start Date",
    en: "Start Date",
  },
  REVIEW_DUE_DATE_NOUN: {
    ko: "Review Due Date",
    en: "Review Due Date",
  },
  REVIEW_REJECT_REASON_NOUN: {
    ko: "반려사유",
    en: "Reason for rejection",
  },
  REVIEW_COMMENT_NOUN: {
    ko: "Comment (optional)",
    en: "Comment (optional)",
  },
  UPLOADER_NOUN: {
    ko: "Uploader",
    en: "Uploader",
  },
  SENDER_NOUN: {
    ko: "Sender",
    en: "Sender",
  },
  RECEIVER_NOUN: {
    ko: "Receiver",
    en: "Receiver",
  },
  EVENT_DATE_NOUN: {
    ko: "Event date",
    en: "Event date",
  },
  WORFKFLOW_HISTORY_COMMENT_NOUN: {
    ko: "Comment",
    en: "Comment",
  },

  // VERB
  CREATE_VERB: {
    ko: "생성",
    en: "Create",
  },
  CANCEL_VERB: {
    ko: "취소",
    en: "Cancel",
  },
  SAVE_VERB: {
    ko: "저장",
    en: "Save",
  },
  DELETE_VERB: {
    ko: "삭제",
    en: "Delete",
  },
  ADD_VERB: {
    ko: "추가",
    en: "Add",
  },
  FINALIZE_VERB: {
    ko: "완료",
    en: "Finalize",
  },
  LOCK_VERB: {
    ko: "잠금",
    en: "Lock",
  },
  UNLOCK_VERB: {
    ko: "잠금 해제",
    en: "Unlock",
  },
  IMPORT_VERB: {
    ko: "불러오기",
    en: "Import",
  },
  INIT_VERB: {
    ko: "초기화",
    en: "Init",
  },
  VIEW_DELETED_VERB: {
    ko: "삭제 항목 보기",
    en: "View Deleted",
  },
  CONFIRM_VERB: {
    ko: "확인",
    en: "Confirm",
  },
  CLOSE_VERB: {
    ko: "Close",
    en: "Close",
  },
  PUBLISH_VERB: {
    ko: "배포",
    en: "Publish",
  },
  BACK_VERB: {
    ko: "뒤로 가기",
    en: "Back",
  },
  UPLOAD_VERB: {
    ko: "업로드",
    en: "Upload",
  },
  DOWNLOAD_VERB: {
    ko: "다운로드",
    en: "Download",
  },
  SAMPLE_DOWNLOAD_VERB: {
    ko: "예제 다운로드",
    en: "Sample Download",
  },
  SEARCH_VERB: {
    ko: "검색",
    en: "Search",
  },
  DOCUMENT_REQUEST_QC_VERB: {
    ko: "QC 요청",
    en: "Request QC",
  },
  CANCEL_DOCUMENT_QC_VERB: {
    ko: "QC 요청 취소",
    en: "Cancel QC request",
  },
  REQUEST_VERB: {
    ko: "요청",
    en: "Request",
  },
  APPROVE_VERB: {
    ko: "승인",
    en: "Approve",
  },
  REJECT_VERB: {
    ko: "반려",
    en: "Reject",
  },
  ENTER_VERB: {
    ko: "입력",
    en: "Enter",
  },
  UPDATE_VERB: {
    ko: "수정",
    en: "Update",
  },
  READ_VERB: {
    ko: "조회",
    en: "Read",
  },
  VIEW_VERB: {
    ko: "보기",
    en: "View",
  },
  DETAIL_VERB: {
    ko: "상세",
    en: "Detail",
  },
  LEAVE_VERB: {
    ko: "나가기",
    en: "Leave",
  },
  STAY_VERB: {
    ko: "머무르기",
    en: "Stay",
  },
  INVITE_VERB: {
    ko: "초대",
    en: "Invite",
  },
  INVITE_TEMPLATE_VERB: {
    ko: "Invite with template",
    en: "Invite with template",
  },
  INVITE_ACCEPT_VERB: {
    ko: "수락",
    en: "Accept",
  },
  INVITE_REJECT_VERB: {
    ko: "거절",
    en: "Reject",
  },
  EDIT_VERB: {
    ko: "수정",
    en: "Edit",
  },

  // EMPTY_VIEW
  TITLE_EMPTY_VIEW: {
    ko: "등록된 데이터가 없습니다.",
    en: "No Data.",
  },
  TITLE_NO_CHANGE_VIEW: {
    ko: "변경된 데이터가 없습니다.",
    en: "There are no changes.",
  },
  NO_STUDY_CREATE_EMPTY_VIEW: {
    ko: "먼저 스터디를 생성해 주세요.",
    en: "Please create a study.",
  },
  NO_STUDY_SET_UP_EMPTY_VIEW: {
    ko: "먼저 스터디를 설정해 주세요.",
    en: "Please set up the study.",
  },
  NO_USER_EMPTY_VIEW: {
    ko: "먼저 유저를 추가해 주세요.",
    en: "Please add the user.",
  },
  NO_ROLE_EMPTY_VIEW: {
    ko: "먼저 역할을 추가해 주세요.",
    en: "Please add the role.",
  },
  NO_TEMPLATE_ADD_EMPTY_VIEW: {
    ko: "먼저 템플릿을 추가해 주세요.",
    en: "Please add the template.",
  },
  NO_TEMPLATE_LOAD_EMPTY_VIEW: {
    ko: "먼저 템플릿을 불러와 주세요.",
    en: "Please load the template.",
  },
  NO_TMF_STRUCTURE_EMPTY_VIEW: {
    ko: "Admin에서 TMF 구조를 발행해 주세요.",
    en: "Please publish the TMF structure in Admin.",
  },
  NO_COMMENT_EMPTY_VIEW: {
    ko: "댓글이 없습니다.",
    en: "No comments.",
  },
  NO_DOCUMENT_EMPTY_VIEW: {
    ko: "문서를 업로드 해 주세요.",
    en: "Please upload a document.",
  },
  FILE_UNSUPPORTED_FORMAT_EMPTY_VIEW: {
    ko: "문서 미리보기를 할 수 없습니다.",
    en: "Cannot preview the document.",
  },
  FILE_LOADING_EMPTY_VIEW: {
    ko: "로딩 중입니다. 잠시만 기다려주세요.",
    en: "Loading. Please wait a moment.",
  },
  FILE_CONVERT_FAIL_EMPTY_VIEW: {
    ko: "PDF 변환에 실패했습니다.",
    en: "Failed to convert to PDF.",
  },
  FILE_EXPORT_SUPPORT_EMPTY_VIEW: {
    ko: "상단의 Export 버튼을 눌러 파일 원본을 다운로드 받을 수 있습니다.",
    en: "You can download the original file by clicking the Export button at the top.",
  },
  EXPORT_SUPPORT_EMPTY_VIEW: {
    ko: "상단의 Export 버튼을 통해 내용을 확인해 주세요.",
    en: "Please check the content by clicking the Export button at the top.",
  },
  NO_SELECTED_ARTIFACT_EMPTY_VIEW: {
    ko: "artifact를 선택해 주세요.",
    en: "Please select an artifact.",
  },
  NO_SELECTED_MILESTONE_EMPTY_VIEW: {
    ko: "마일스톤을 선택해 주세요.",
    en: "Please select a milestone.",
  },
  DW_HISTORY_EMPTY_VIEW: {
    ko: "파일을 먼저 생성해 주세요.",
    en: "Please create the file first.",
  },

  // VALIDATION
  REQUIRED_FIELD_EMPTY_VALIDATION: {
    ko: "필수 입력 항목입니다.",
    en: "Please fill in the required field.",
  },
  SAVE_FAIL_BY_REQUIRED_FIELD_EMPTY_VALIDATION: {
    ko: "필수 값 미입력으로 저장할 수 없습니다.",
    en: "Cannot save due to missing required values.",
  },
  INCORRECT_FORMAT_VALIDATION: {
    ko: "올바른 {property} 형식이 아닙니다. 다시 입력해 주세요.",
    en: "Please enter a valid {property} format.",
  },
  INCORRECT_FILE_VALIDATION: {
    ko: "올바른 파일 형식이 아닙니다. 수정 후 다시 업로드해 주세요.",
    en: "Please select a valid file format.",
  },
  LEAST_ONE_VALIDATION: {
    ko: "반드시 하나 이상의 {property}(을)를 포함 해야합니다. 다시 선택해 주세요.",
    en: "You must include at least one {property}. Please select again.",
  },
  INVALID_DOCUMENT_VALIDATION: {
    ko: "유효하지 않은 문서입니다. 정보를 수정해 주세요.",
    en: "Invalid document. Please change the information.",
  },
  INVALID_DOCUMENT_PROPERTIES_VALIDATION: {
    ko: "유효하지 않은 문서 속성입니다. 정보를 수정하고 다시 저장해 주세요.",
    en: "Invalid document properties. Please change the information.",
  },
  INVALID_DOCUMENT_PROPERTY_ZONE_VALIDATION: {
    ko: "유효하지 않은 Zone을 참조하고 있습니다.",
    en: "It refers to an invalid Zone.",
  },
  INVALID_DOCUMENT_PROPERTY_SECTION_VALIDATION: {
    ko: "유효하지 않은 Section을 참조하고 있습니다.",
    en: "It refers to an invalid Section.",
  },
  INVALID_DOCUMENT_PROPERTY_ARTIFACT_VALIDATION: {
    ko: "유효하지 않은 Artifact를 참조하고 있습니다.",
    en: "It refers to an invalid Artifact.",
  },
  INVALID_DOCUMENT_PROPERTY_SUB_ARTIFACT_VALIDATION: {
    ko: "유효하지 않은 문서 종류를 참조하고 있습니다.",
    en: "It refers to an invalid Document Type.",
  },
  INVALID_DOCUMENT_PROPERTY_COUNTRY_VALIDATION: {
    ko: "유효하지 않은 국가를 참조하고 있습니다.",
    en: "It refers to an invalid Country.",
  },
  INVALID_DOCUMENT_PROPERTY_SITE_VALIDATION: {
    ko: "유효하지 않은 실시기관를 참조하고 있습니다.",
    en: "It refers to an invalid Site.",
  },
  INVALID_MILESTONE_VALIDATION: {
    ko: "마일스톤이 유효하지 않습니다. 정보를 수정해 주세요.",
    en: "Invalid milestone. Please change the information.",
  },
  INVALID_STRUCTURE_VALIDATION: {
    ko: "{property}이(가) 유효하지 않습니다. 정보를 수정해 주세요.",
    en: "Invalid {property}. Please change the information.",
  },
  RANGE_OF_CHARACTERS_VALIDATION: {
    ko: "{min}자 이상 {max}자 이하로 입력해 주세요.",
    en: "Please enter between {min} and {max} characters.",
  },
  DUPLICATED_ITEM_VALIDATION: {
    ko: "이미 존재하는 {property}입니다. 다른 {property}을(를) 입력해 주세요.",
    en: "The {property} already exists. Please enter a different {property}.",
  },
  MAX_LIMIT_EXCEEDED_VALIDATION: {
    ko: "최대 {max}개까지 생성할 수 있습니다.",
    en: "You can create up to {max} items.",
  },

  // PRIVILEGE_DESCRIPTION
  TMF_READ_PRIVILEGE_DESCRIPTION: {
    ko: "TMF에서, 소속 기관의 문서와 진행현황을 확인할 수 있습니다.",
    en: "In TMF, you can view documents and progress of your organization.",
  },
  DOCUMENT_WRITE_PRIVILEGE_DESCRIPTION: {
    ko: "TMF에서, 문서를 업로드할 수 있습니다.",
    en: "In TMF, you can upload documents.",
  },
  DOCUMENT_QC_REQUEST_PRIVILEGE_DESCRIPTION: {
    ko: "문서의 QC를 요청할 수 있습니다.",
    en: "You can request QC of documents.",
  },
  DOCUMENT_APPROVE_PRIVILEGE_DESCRIPTION: {
    ko: "문서를 승인 및 반려할 수 있습니다.",
    en: "You can approve or reject documents.",
  },
  DOCUMENT_LOCK_PRIVILEGE_DESCRIPTION: {
    ko: "문서를 잠금 및 잠금 해제 할 수 있습니다.",
    en: "You can lock and unlock documents.",
  },
  DOCUMENT_COMMENT_PRIVILEGE_DESCRIPTION: {
    ko: "문서 댓글 작성 및 편집할 수 있습니다.",
    en: "You can write and edit comments on documents.",
  },
  MILESTONE_READ_PRIVILEGE_DESCRIPTION: {
    ko: "TMF Milestone을 조회할 수 있습니다.",
    en: "You can view TMF Milestone.",
  },
  MILESTONE_MANAGE_PRIVILEGE_DESCRIPTION: {
    ko: "TMF Milestone을 조회하고 수정할 수 있습니다.",
    en: "You can view and edit TMF Milestone.",
  },
  ADMIN_READ_PRIVILEGE_DESCRIPTION: {
    ko: "TMF Admin를 조회할 수 있습니다.",
    en: "You can view TMF Admin.",
  },
  ADMIN_MANAGE_PRIVILEGE_DESCRIPTION: {
    ko: "TMF Admin을 조회하고 수정할 수 있습니다.",
    en: "You can view and edit TMF Admin.",
  },
  AUDIT_TRAIL_DOWNLOAD_PRIVILEGE_DESCRIPTION: {
    ko: "Archiver에서, Audit Trail을 다운로드 할 수 있습니다.",
    en: "In Archiver, you can download the Audit Trail.",
  },

  // TITLE
  CREATE_STUDY_TITLE: {
    ko: "스터디 생성",
    en: "Create Study",
  },
  ADD_COUNTRY_TITLE: {
    ko: "국가 추가",
    en: "Add Country",
  },
  ADD_USER_TITLE: {
    ko: "유저 초대",
    en: "Invite User",
  },
  ADD_ROLE_TITLE: {
    ko: "역할 추가",
    en: "Add Role",
  },
  ADD_MILESTONE_TITLE: {
    ko: "마일스톤 추가",
    en: "Add Milestone",
  },
  ADD_ZONE_TITLE: {
    ko: "Zone 추가",
    en: "Add Zone",
  },
  ADD_SECTION_TITLE: {
    ko: "Section 추가",
    en: "Add Section",
  },
  ADD_ARTIFACT_TITLE: {
    ko: "Artifact 추가",
    en: "Add Artifact",
  },
  ADD_SUB_ARTIFACT_TITLE: {
    ko: "문서 추가",
    en: "Add Document",
  },
  ADD_TEMPLATE_TITLE: {
    ko: "템플릿 추가",
    en: "Add Template",
  },
  SAVE_MILESTONE_TITLE: {
    ko: "마일스톤 저장",
    en: "Save Milestone",
  },
  SAVE_ZONE_LIST_TITLE: {
    ko: "Zone List 저장",
    en: "Save Zone List",
  },
  PUBLISH_TMF_STRUCTURE_TITLE: {
    ko: "TMF 구조 발행",
    en: "Publish TMF Structure",
  },
  DELETE_MILESTONE_TITLE: {
    ko: "마일스톤 삭제",
    en: "Delete Milestone",
  },
  DELETE_ARTIFACT_TITLE: {
    ko: "Artifact 삭제",
    en: "Delete Artifact",
  },
  DELETE_SECTION_TITLE: {
    ko: "Section 삭제",
    en: "Delete Section",
  },
  DELETE_ZONE_TITLE: {
    ko: "Zone 삭제",
    en: "Delete Zone",
  },
  DELETE_DOCUMENT_TITLE: {
    ko: "문서 삭제",
    en: "Delete Document",
  },
  EDIT_NAME_TITLE: {
    ko: "이름 수정",
    en: "Edit Name",
  },
  DOWNLOAD_TEMPLATE_TITLE: {
    ko: "템플릿 다운로드",
    en: "Download Template",
  },
  DOWNLOAD_DOCUMENT_LIST_BUTTON_TITLE: {
    ko: "문서 리스트 다운로드",
    en: "Download Document List",
  },
  DOWNLOAD_DOCUMENT_LIST_DIALOG_TITLE: {
    ko: "TMF List Download",
    en: "TMF List Download",
  },
  UPLOAD_FILE_TITLE: {
    ko: "파일 업로드",
    en: "Upload File",
  },
  RETRY_CONVERT_TITLE: {
    ko: "PDF 변환 재시도",
    en: "Retry PDF Conversion",
  },
  INVITATION_COUNT_TITLE: {
    ko: "{count}개의 스터디에 초대되었습니다.",
    en: "Invited to {count} studies.",
  },
  DELETE_TEMPLATE_TITLE: {
    ko: "템플릿 삭제",
    en: "Delete Template",
  },
  EDIT_CANCLE_TITLE: {
    ko: "취소",
    en: "Cancel",
  },
  EDIT_COMPLETE_TITLE: {
    ko: "수정완료",
    en: "Edit Complete",
  },
  STRUCTURE_INIT_TITLE: {
    ko: "구조 초기화",
    en: "Structure Init",
  },
  DELETE_COMMENT_TITLE: {
    ko: "댓글 삭제",
    en: "Delete Comment",
  },
  CREATE_ARCHIVER_TITLE: {
    ko: "파일 생성",
    en: "Create File",
  },

  // PLACEHOLDER
  SELECT_ORGANIZATION_PLACEHOLDER: {
    ko: "조직을 선택해 주세요.",
    en: "Please select the organization.",
  },
  SELECT_TRIAL_PLACEHOLDER: {
    ko: "Trial을 선택해 주세요.",
    en: "Please select the trial.",
  },
  SELECT_COUNTRY_PLACEHOLDER: {
    ko: "국가를 선택해 주세요.",
    en: "Please select the country.",
  },
  SELECT_ROLE_PLACEHOLDER: {
    ko: "역할을 선택해 주세요.",
    en: "Please select the role.",
  },
  SELECT_SITE_PLACEHOLDER: {
    ko: "실시기관을 선택해 주세요.",
    en: "Please select the site.",
  },
  SELECT_MILESTONE_GROUP_PLACEHOLDER: {
    ko: "마일스톤 그룹을 선택해 주세요.",
    en: "Please select a milestone group.",
  },
  SELECT_MILESTONE_PLACEHOLDER: {
    ko: "마일스톤을 선택해 주세요.",
    en: "Please select the milestone.",
  },
  SELECT_STUDY_MILESTONE_PLACEHOLDER: {
    ko: "스터디 마일스톤을 선택해 주세요.",
    en: "Please select the study milestone.",
  },
  SELECT_COUNTRY_MILESTONE_PLACEHOLDER: {
    ko: "국가 마일스톤을 선택해 주세요.",
    en: "Please select the country milestone.",
  },
  SELECT_SITE_MILESTONE_PLACEHOLDER: {
    ko: "실시기관 마일스톤을 선택해 주세요.",
    en: "Please select the site milestone.",
  },
  SELECT_DATE_CONVENTION_PLACEHOLDER: {
    ko: "날짜 규칙을 선택해 주세요.",
    en: "Please select the date convention.",
  },
  SELECT_ZONE_PLACEHOLDER: {
    ko: "Zone을 선택해 주세요.",
    en: "Please select the Zone.",
  },
  SELECT_SECTION_PLACEHOLDER: {
    ko: "Section을 선택해 주세요.",
    en: "Please select the Section.",
  },
  SELECT_ARTIFACT_PLACEHOLDER: {
    ko: "Artifact을 선택해 주세요.",
    en: "Please select the Artifact.",
  },
  SELECT_LEVEL_PLACEHOLDER: {
    ko: "레벨을 선택해 주세요.",
    en: "Please select the Level.",
  },
  SELECT_SUB_ARTIFACT_PLACEHOLDER: {
    ko: "문서 종류를 선택해 주세요.",
    en: "Please select the document type.",
  },
  SELECT_TMF_LEVEL_DOCUMENT_PLACEHOLDER: {
    ko: "Trial 레벨 문서를 선택해 주세요.",
    en: "Please select the Trial level document.",
  },
  SELECT_COUNTRY_LEVEL_DOCUMENT_PLACEHOLDER: {
    ko: "국가 레벨 문서를 선택해 주세요.",
    en: "Please select the country level document.",
  },
  SELECT_SITE_LEVEL_DOCUMENT_PLACEHOLDER: {
    ko: "실시기관 레벨 문서를 선택해 주세요.",
    en: "Please select the site level document.",
  },
  ENTER_STUDY_NAME_PLACEHOLDER: {
    ko: "스터디 이름을 입력해 주세요.",
    en: "Please enter the study name.",
  },
  ENTER_PROTOCOL_NUMBER_PLACEHOLDER: {
    ko: "프로토콜 번호를 입력해 주세요.",
    en: "Please enter the protocol number.",
  },
  ENTER_USER_NAME_PLACEHOLDER: {
    ko: "유저 이름을 입력해 주세요.",
    en: "Please enter the user name.",
  },
  ENTER_EMAIL_PLACEHOLDER: {
    ko: "이메일을 입력해 주세요.",
    en: "Please enter the email.",
  },
  ENTER_ROLE_NAME_PLACEHOLDER: {
    ko: "역할 이름을 입력해 주세요.",
    en: "Please enter the role name.",
  },
  ENTER_ROLE_DESCRIPTION_PLACEHOLDER: {
    ko: "역할 설명을 입력해 주세요.",
    en: "Please enter the role description.",
  },
  ENTER_ID_PLACEHOLDER: {
    ko: "ID를 입력해 주세요.",
    en: "Please enter the ID.",
  },
  ENTER_STUDY_DESCRIPTION_PLACEHOLDER: {
    ko: "스터디 설명을 입력해 주세요.",
    en: "Please enter the study description.",
  },
  ENTER_COUNTRY_DESCRIPTION_PLACEHOLDER: {
    ko: "국가 설명을 입력해 주세요.",
    en: "Please enter the country description.",
  },
  ENTER_SITE_DESCRIPTION_PLACEHOLDER: {
    ko: "실시기관 설명을 입력해 주세요.",
    en: "Please enter the site description.",
  },
  ENTER_DESCRIPTION_PLACEHOLDER: {
    ko: "설명을 입력해 주세요.",
    en: "Please enter the description.",
  },
  ENTER_ICH_CODE_PLACEHOLDER: {
    ko: "ICH 코드를 입력해 주세요.",
    en: "Please enter the ICH code.",
  },
  ENTER_UNIQUE_ID_PLACEHOLDER: {
    ko: "Unique ID를 입력해 주세요.",
    en: "Please enter the Unique ID.",
  },
  ENTER_NAME_PLACEHOLDER: {
    ko: "이름을 입력해 주세요.",
    en: "Please enter the name.",
  },
  ENTER_DOCUMENT_NAME_PLACEHOLDER: {
    ko: "문서 이름을 입력해 주세요.",
    en: "Please enter the document name.",
  },
  ENTER_DOCUMENT_DELETED_REASON_PLACEHOLDER: {
    ko: "삭제 사유를 입력해 주세요.",
    en: "Please enter the document deletion reason.",
  },
  ENTER_DOCUMENT_FILE_UPLOAD_PLACEHOLDER: {
    ko: "파일을 업로드해 주세요.",
    en: "Please upload a file.",
  },
  ENTER_COMMENT_PLACEHOLDER: {
    ko: "댓글을 입력해 주세요.",
    en: "Please enter the comment.",
  },
  ENTER_SITE_NAME_PLACEHOLDER: {
    ko: "실시기관 이름을 입력해 주세요.",
    en: "Please enter the site name.",
  },
  ENTER_SITE_CODE_PLACEHOLDER: {
    ko: "실시기관 코드를 입력해 주세요.",
    en: "Please enter the site code.",
  },
  ENTER_TEMPLATE_TITLE_PLACEHOLDER: {
    ko: "템플릿 제목을 입력해 주세요.",
    en: "Please enter the template title.",
  },
  ENTER_MILESTONE_NAME_PLACEHOLDER: {
    ko: "마일스톤 이름을 입력해 주세요.",
    en: "Please enter the milestone name.",
  },
  SELECT_REVIEWER_NAME_PLACEHOLDER: {
    ko: "Enter reviewer’s name or email to add",
    en: "Enter reviewer’s name or email to add",
  },
  SELECT_START_DATE_PLACEHOLDER: {
    ko: "Select start date",
    en: "Select start date",
  },
  SELECT_REVIEW_DUE_DATE_PLACEHOLDER: {
    ko: "Select due date",
    en: "Select due date",
  },
  SELECT_REJECT_QC_REASON_PLACEHOLDER: {
    ko: "반려사유 선택",
    en: "Select reason for rejection",
  },
  ENTER_REVIEW_COMMENT_PLACEHOLDER: {
    ko: "Comment 입력",
    en: "Enter comment",
  },

  // HELPER_TEXT
  ICH_CODE_FORMAT_HELPER_TEXT: {
    ko: ".를 입력해서 ICH 코드를 입력해 주세요. ,을 입력해서 여러 개의 ICH 코드를 입력할 수 있습니다.",
    en: "Enter the ICH code by entering a period. You can enter multiple ICH codes by entering a comma.",
  },
  PUBLISH_TMF_STRUCTURE_HELPER_TEXT: {
    ko: "배포 시, 마일스톤과 Zone List 모두 버전 업데이트됩니다.",
    en: "When published, both milestones and zone lists are updated.",
  },
  SELECT_DOCUMENT_HELPER_TEXT: {
    ko: "업로드 할 문서를 선택해 주세요.",
    en: "Please select a document.",
  },
  DRAG_AND_DROP_DOCUMENT_HELPER_TEXT: {
    ko: "업로드 할 파일을 드롭해 주세요.",
    en: "Drop the file to upload.",
  },
  DRAG_AND_DROP_DOCUMENT_DESCRIPTION_HELPER_TEXT: {
    ko: "미발행 문서에 업로드됩니다.",
    en: "It will be uploaded to the unpublished document.",
  },
  FILE_SIZE_HELPER_TEXT: {
    ko: "최대 {num}MB의 파일을 선택해 주세요.",
    en: "Please select a file up to {num}MB.",
  },
  FILE_FORMAT_HELPER_TEXT: {
    ko: "업로드 가능 파일 형식: {format}",
    en: "Uploadable file format: {format}",
  },
  FILE_SIZE_FORMAT_HELPER_TEXT: {
    ko: "최대 {num}MB의 {type}파일을 업로드할 수 있습니다.",
    en: "You can upload a {type}file up to {num}MB.",
  },
  FILE_UPLOAD_HELPER_TEXT: {
    ko: "Drag and Drop 또는 파일 업로드 버튼울 눌러주세요.",
    en: "Please drag and drop or press the file upload button.",
  },
  USED_MILESTONE_HELPER_TEXT: {
    ko: "사용 중인 마일스톤입니다.",
    en: "Milestone in use.",
  },
  TMF_STRUCTURE_PUBLISH_HELPER_TEXT: {
    ko: "배포 시, 마일스톤과 Zone 리스트 모두 버전 업데이트됩니다.",
    en: "When published, both milestones and zone lists are updated.",
  },

  // Prompt
  LEAVE_FORM_PROMPT: {
    ko: "폼을 나가시겠습니까? 현재까지 작성한 내용이 모두 초기화됩니다.",
    en: "Do you want to leave the form? All the content you have written so far will be reset.",
  },
  LEAVE_PAGE_PROMPT: {
    ko: "페이지를 떠나시겠습니까? 현재까지 작성한 내용이 모두 삭제됩니다.",
    en: "Do you want to leave the page? All the content you have written so far will be deleted.",
  },
  CLOSE_UPLOAD_PROGRESS_PROMPT: {
    ko: "페이지를 떠나시겠습니까? 모든 파일의 업로드가 취소됩니다.",
    en: "Do you want to leave the page? All file uploads will be canceled.",
  },
  DELETE_MILESTONE_PROMPT: {
    ko: "마일스톤을 삭제하시겠습니까?",
    en: "Do you want to delete the milestone?",
  },
  DELETE_TEMPLATE_PROMPT: {
    ko: "템플릿을 삭제하시겠습니까?",
    en: "Do you want to delete the template?",
  },
  DELETE_PROMPT: {
    ko: "{property}을(를) 삭제하시겠습니까?",
    en: "Do you want to delete the {property}?",
  },
  EDIT_CANCLE_PROMPT: {
    ko: "수정을 취소하시겠습니까?",
    en: "Do you want to cancel the edit?",
  },
  INIT_STRUCTURE_PROMPT: {
    ko: "TMF 구조를 초기화하시겠습니까?",
    en: "Do you want to initialize the TMF structure?",
  },
  DELETE_COMMENT_PROMPT: {
    ko: "댓글을 삭제하시겠습니까?",
    en: "Do you want to delete the comment?",
  },
  DOWNLOAD_DOCUMENT_LIST_PROMPT: {
    ko: "TMF List를 다운로드 하겠습니까?",
    en: "Are you want to download TMF List?",
  },
  APPROVE_DOCUMENT_QC_PROMPT: {
    ko: "QC 요청을 승인하시겠습니까?",
    en: "Do you want to approve the document's QC request?",
  },
  REJECT_DOCUMENT_QC_PROMPT: {
    ko: "QC 요청을 반려하시겠습니까?",
    en: "Do you want to reject the document's QC request?",
  },

  // TOAST
  ORGANIZATION_LOAD_SUCCESS_TOAST: {
    ko: "organization 데이터 불러오기에 실패했습니다. ({message})",
    en: "Failed to load organization data. ({message})",
  },

  COUNTRY_LOAD_FAIL_TOAST: {
    ko: "국가 데이터 불러오기에 실패했습니다. ({message})",
    en: "Failed to load Country data. ({message})",
  },

  STUDY_LIST_LOAD_FAIL_TOAST: {
    ko: "스터디 목록 데이터 불러오기에 실패했습니다. ({message})",
    en: "Failed to load Study data. ({message})",
  },
  STUDY_INACCESSIBLE_TOAST: {
    ko: "Study 접속 권한이 없습니다.",
    en: "You have no access to this study.",
  },
  STUDY_LOAD_FAIL_TOAST: {
    ko: "스터디 데이터 불러오기에 실패했습니다. ({message})",
    en: "Failed to load Study data. ({message})",
  },

  STUDY_CREATE_SUCCESS_TOAST: {
    ko: "스터디 생성에 성공했습니다.",
    en: "The study settings created successfully.",
  },
  STUDY_CREATE_FAIL_TOAST: {
    ko: "스터디 생성에 실패했습니다. ({message})",
    en: "Failed to create the study. ({message})",
  },
  STUDY_UPDATE_SUCCESS_TOAST: {
    ko: "스터디 수정에 성공했습니다.",
    en: "The study settings updated successfully.",
  },
  STUDY_UPDATE_FAIL_TOAST: {
    ko: "스터디 수정에 실패했습니다. ({message})",
    en: "Failed to update the study. ({message})",
  },
  STUDY_LOCK_SUCCESS_TOAST: {
    ko: "스터디 잠금에 성공했습니다.",
    en: "The study locked successfully.",
  },
  STUDY_LOCK_FAIL_TOAST: {
    ko: "스터디 잠금에 실패했습니다. ({message})",
    en: "Failed to lock the study. ({message})",
  },
  STUDY_UNLOCK_SUCCESS_TOAST: {
    ko: "스터디 잠금 해제에 성공했습니다.",
    en: "The study unlocked successfully.",
  },
  STUDY_UNLOCK_FAIL_TOAST: {
    ko: "스터디 잠금 해제에 실패했습니다. ({message})",
    en: "Failed to unlock the study. ({message})",
  },

  USER_LOAD_FAIL_TOAST: {
    ko: "유저 데이터 불러오기에 실패했습니다. ({message})",
    en: "Failed to load User data. ({message})",
  },
  USER_UPDATE_SUCCESS_TOAST: {
    ko: "유저 수정에 성공했습니다.",
    en: "The user updated successfully.",
  },
  USER_UPDATE_FAIL_TOAST: {
    ko: "유저 수정에 실패했습니다. ({message})",
    en: "Failed to update the user. ({message})",
  },
  INVITE_USER_SUCCESS_TOAST: {
    ko: "유저 초대에 성공했습니다.",
    en: "The user invited successfully.",
  },

  ROLE_LOAD_FAIL_TOAST: {
    ko: "역할 데이터 불러오기에 실패했습니다. ({message})",
    en: "Failed to load Role data. ({message})",
  },
  ROLE_UPDATE_SUCCESS_TOAST: {
    ko: "역할 수정에 성공했습니다.",
    en: "The role updated successfully.",
  },
  ROLE_UPDATE_FAIL_TOAST: {
    ko: "역할 수정에 실패했습니다. ({message})",
    en: "Failed to update the role. ({message})",
  },

  PRIVILEGE_LOAD_FAIL_TOAST: {
    ko: "권한 데이터 불러오기에 실패했습니다. ({message})",
    en: "Failed to load Privilege data. ({message})",
  },
  PRIVILEGE_UPDATE_SUCCESS_TOAST: {
    ko: "권한 수정에 성공했습니다.",
    en: "The privilege updated successfully.",
  },
  PRIVILEGE_UPDATE_FAIL_TOAST: {
    ko: "권한 수정에 실패했습니다. ({message})",
    en: "Failed to update the privilege. ({message})",
  },

  TEMPLATE_LOAD_FAIL_TOAST: {
    ko: "템플릿 데이터 불러오기에 실패했습니다. ({message})",
    en: "Failed to load Template data. ({message})",
  },
  TEMPLATE_CREATE_SUCCESS_TOAST: {
    ko: "템플릿 생성에 성공했습니다.",
    en: "The template created successfully.",
  },
  TEMPLATE_CREATE_FAIL_TOAST: {
    ko: "템플릿 생성에 실패했습니다. ({message})",
    en: "Failed to create the template. ({message})",
  },
  TEMPLATE_DELETE_SUCCESS_TOAST: {
    ko: "템플릿 삭제에 성공했습니다.",
    en: "The template deleted successfully.",
  },
  TEMPLATE_DELETE_FAIL_TOAST: {
    ko: "템플릿 삭제에 실패했습니다. ({message})",
    en: "Failed to delete the template. ({message})",
  },

  MILESTONE_LOAD_FAIL_TOAST: {
    ko: "마일스톤 데이터 불러오기에 실패했습니다. ({message})",
    en: "Failed to load Milestone data. ({message})",
  },
  MILESTONE_SAVE_SUCCESS_TOAST: {
    ko: "마일스톤 저장에 성공했습니다.",
    en: "The milestone saved successfully.",
  },
  MILESTONE_SAVE_FAIL_TOAST: {
    ko: "마일스톤 저장에 실패했습니다. ({message})",
    en: "Failed to save the milestone. ({message})",
  },

  ZONE_LIST_LOAD_FAIL_TOAST: {
    ko: "Zone List 데이터 불러오기에 실패했습니다. ({message})",
    en: "Failed to load Zone List data. ({message})",
  },
  ZONE_LIST_SAVE_SUCCESS_TOAST: {
    ko: "Zone List 저장에 성공했습니다.",
    en: "The Zone List saved successfully.",
  },
  ZONE_LIST_SAVE_FAIL_TOAST: {
    ko: "Zone List 저장에 실패했습니다. ({message})",
    en: "Failed to save the Zone List. ({message})",
  },

  TMF_STRUCTURE_PUBLISH_SUCCESS_TOAST: {
    ko: "TMF 구조 배포에 성공했습니다.",
    en: "The TMF structure published successfully.",
  },
  TMF_STRUCTURE_PUBLISH_FAIL_TOAST: {
    ko: "TMF 구조 배포에 실패했습니다. ({message})",
    en: "Failed to publish the TMF structure. ({message})",
  },

  DOCUMENT_LIST_DOWNLOAD_SUCCESS_TOAST: {
    ko: "문서 리스트 다운로드에 성공했습니다.",
    en: "The document list downloaded successfully.",
  },
  DOCUMENT_LIST_DOWNLOAD_FAIL_TOAST: {
    ko: "문서 리스트 다운로드에 실패했습니다. ({message})",
    en: "Failed to download the document list. ({message})",
  },

  DOCUMENT_UPLOAD_FAIL_TOAST: {
    ko: "문서 업로드에 실패했습니다. ({message})",
    en: "Failed to upload the document. ",
  },
  DOCUMENT_UPLOAD_SUCCESS_TOAST: {
    ko: "문서 업로드에 성공했습니다.",
    en: "The document uploaded successfully.",
  },
  DOCUMENT_CREATE_SUCCESS_TOAST: {
    ko: "문서 생성에 성공했습니다.",
    en: "The document created successfully.",
  },
  DOCUMENT_CREATE_FAIL_TOAST: {
    ko: "문서 생성에 실패했습니다. ({message})",
    en: "Failed to create the document. ({message})",
  },

  DOCUMENT_LIST_LOAD_FAIL_TOAST: {
    ko: "문서 데이터 목록 불러오기에 실패했습니다. ({message})",
    en: "Failed to load Document list data. ({message})",
  },
  DOCUMENT_LOAD_FAIL_TOAST: {
    ko: "문서 데이터 불러오기에 실패했습니다. ({message})",
    en: "Failed to load Document data. ({message})",
  },

  DOCUMENT_FILE_LOAD_FAIL_TOAST: {
    ko: "파일 불러오기에 실패했습니다. ({message})",
    en: "Failed to load Document file. ({message})",
  },

  DOCUMENT_DOWNLOAD_SUCCESS_TOAST: {
    ko: "문서 다운로드에 성공했습니다.",
    en: "The document downloaded successfully.",
  },
  DOCUMENT_DOWNLOAD_FAIL_TOAST: {
    ko: "문서 다운로드에 실패했습니다. ({message})",
    en: "Failed to download the document. ({message})",
  },
  DOCUMENT_SAVE_SUCCESS_TOAST: {
    ko: "문서 저장에 성공했습니다.",
    en: "The document saved successfully.",
  },
  DOCUMENT_SAVE_FAIL_TOAST: {
    ko: "문서 저장에 실패했습니다. ({message})",
    en: "Failed to save the document. ({message})",
  },
  DOCUMENT_APPROVE_SUCCESS_TOAST: {
    ko: "QC 요청이 승인되었습니다.",
    en: "The document's QC approved successfully.",
  },
  DOCUMENT_APPROVE_FAIL_TOAST: {
    ko: "QC 요청 승인에 실패했습니다. ({message})",
    en: "Failed to approve the document's QC. ({message})",
  },
  DOCUMENT_QC_REQUEST_SUCCESS_TOAST: {
    ko: "문서 QC 요청에 성공했습니다.",
    en: "the requested document's QC was successful.",
  },
  DOCUMENT_QC_REQUEST_FAIL_TOAST: {
    ko: "문서 QC 요청에 실패했습니다. ({message})",
    en: "Failed to request the document qc. ({message})",
  },
  DOCUMENT_REJECT_SUCCESS_TOAST: {
    ko: "QC 요청이 반려되었습니다.",
    en: "The document's QC rejected successfully.",
  },
  DOCUMENT_REJECT_FAIL_TOAST: {
    ko: "QC 요청 반려에 실패했습니다. ({message})",
    en: "Failed to reject the document's QC. ({message})",
  },
  DOCUMENT_FINALIZE_SUCCESS_TOAST: {
    ko: "문서가 완료처리되었습니다.",
    en: "The document has been finalized successfully.",
  },
  DOCUMENT_FINALIZE_FAIL_TOAST: {
    ko: "문서 완료처리에 실패했습니다. ({message})",
    en: "Failed to finalize the document. ({message})",
  },
  DOCUMENT_LOCK_SUCCESS_TOAST: {
    ko: "문서 잠금에 성공했습니다.",
    en: "The document locked successfully.",
  },
  DOCUMENT_LOCK_FAIL_TOAST: {
    ko: "문서 잠금에 실패했습니다. ({message})",
    en: "Failed to lock the document. ({message})",
  },
  DOCUMENT_UNLOCK_SUCCESS_TOAST: {
    ko: "문서 잠금 해제에 성공했습니다.",
    en: "The document unlocked successfully.",
  },
  DOCUMENT_UNLOCK_FAIL_TOAST: {
    ko: "문서 잠금 해제에 실패했습니다. ({message})",
    en: "Failed to unlock the document. ({message})",
  },
  DOCUMENT_COMMENT_LOAD_FAIL_TOAST: {
    ko: "댓글 데이터 불러오기에 실패했습니다. ({message})",
    en: "Failed to load Comment data. ({message})",
  },
  DOCUMENT_COMMENT_CREATE_FAIL_TOAST: {
    ko: "댓글 등록에 실패했습니다. ({message})",
    en: "Failed to create the comment. ({message})",
  },
  DOCUMENT_COMMENT_CREATE_SUCCESS_TOAST: {
    ko: "댓글 등록에 성공했습니다.",
    en: "The comment created successfully.",
  },
  DOCUMENT_COMMENT_UPDATE_SUCCESS_TOAST: {
    ko: "댓글 수정에 성공했습니다.",
    en: "The comment updated successfully.",
  },
  DOCUMENT_COMMENT_UPDATE_FAIL_TOAST: {
    ko: "댓글 수정에 실패했습니다. ({message})",
    en: "Failed to update the comment. ({message})",
  },
  DOCUMENT_COMMENT_DELETE_SUCCESS_TOAST: {
    ko: "댓글 삭제에 성공했습니다.",
    en: "The comment deleted successfully.",
  },
  DOCUMENT_COMMENT_DELETE_FAIL_TOAST: {
    ko: "댓글 삭제에 실패했습니다. ({message})",
    en: "Failed to delete the comment. ({message})",
  },

  PUBLISHED_DOCUMENT_LOAD_FAIL_TOAST: {
    ko: "배포된 문서 데이터 불러오기에 실패했습니다. ({message})",
    en: "Failed to load Published Document data. ({message})",
  },
  DASHBOARD_LOAD_FAIL_TOAST: {
    ko: "대시보드 데이터 불러오기에 실패했습니다. ({message})",
    en: "Failed to load Dashboard data. ({message})",
  },
  DOCUMENT_OWNER_LOAD_FAIL_TOAST: {
    ko: "문서 수정자 불러오기에 실패했습니다. ({message})",
    en: "Failed to load Document owner data. ({message})",
  },
  DOCUMENT_REVIEWABLE_USER_LOAD_FAIL_TOAST: {
    ko: "문서 Reviewer 불러오기에 실패했습니다. ({message})",
    en: "Failed to load Document reviewer. ({message})",
  },
  DOCUMENT_DELETE_SUCCESS_TOAST: {
    ko: "문서 삭제에 성공했습니다.",
    en: "The document deleted successfully.",
  },
  DOCUMENT_DELETE_FAIL_TOAST: {
    ko: "문서 삭제에 실패했습니다. ({message})",
    en: "Failed to delete the document. ({message})",
  },
  DOCUMENT_ALL_UPLOAD_SUCCESS_TOAST: {
    ko: "전체 문서 업로드에 성공했습니다.",
    en: "All documents uploaded successfully.",
  },
  DOCUMENT_ALL_UPLOAD_FAIL_TOAST: {
    ko: "전체 문서 업로드에 실패했습니다. ({message})",
    en: "Failed to upload all documents. ({message})",
  },
  SITE_LOAD_FAIL_TOAST: {
    ko: "실시기관 데이터 불러오기에 실패했습니다. ({message})",
    en: "Failed to load Site data. ({message})",
  },
  RESEND_INVITATION_SUCCESS_TOAST: {
    ko: "초대 메일 재전송에 성공했습니다.",
    en: "The invitation email resent successfully.",
  },
  RESEND_INVITATION_FAIL_TOAST: {
    ko: "초대 메일 재전송에 실패했습니다. ({message})",
    en: "Failed to resend the invitation email. ({message})",
  },
  STUDY_INVITATION_LOAD_FAIL_TOAST: {
    ko: "스터디 초대 데이터 불러오기에 실패했습니다. ({message})",
    en: "Failed to load Study invitation data. ({message})",
  },
  INVITATION_ACCEPT_SUCCESS_TOAST: {
    ko: "스터디 초대에 수락했습니다.",
    en: "The invitation accepted successfully.",
  },
  INVITATION_ACCEPT_FAIL_TOAST: {
    ko: "스터디 초대 수락에 실패했습니다. ({message})",
    en: "Failed to accept the invitation. ({message})",
  },
  INVITATION_REJECT_SUCCESS_TOAST: {
    ko: "스터디 초대에 거절했습니다.",
    en: "The invitation rejected successfully.",
  },
  INVITATION_REJECT_FAIL_TOAST: {
    ko: "스터디 초대 거절에 실패했습니다. ({message})",
    en: "Failed to reject the invitation. ({message})",
  },
  STRUCTURE_CREATE_SUCCESS_TOAST: {
    ko: "TMF 구조 생성에 성공했습니다.",
    en: "The structure created successfully.",
  },
  STRUCTURE_CREATE_FAIL_TOAST: {
    ko: "TMF 구조 생성에 실패했습니다. ({message})",
    en: "Failed to create the structure. ({message})",
  },
  STRUCTURE_INIT_SUCCESS_TOAST: {
    ko: "TMF 구조 초기화에 성공했습니다.",
    en: "The structure initialized successfully.",
  },
  STRUCTURE_INIT_FAIL_TOAST: {
    ko: "TMF 구조 초기화에 실패했습니다. ({message})",
    en: "Failed to initialize the structure. ({message})",
  },
  STRUCTURE_LOAD_FAIL_TOAST: {
    ko: "TMF 구조 데이터 불러오기에 실패했습니다. ({message})",
    en: "Failed to load the TMF structure. ({message})",
  },
  STRUCTURE_SAVE_FAIL_TOAST: {
    ko: "TMF 구조 저장에 실패했습니다. ({message})",
    en: "Failed to save the TMF structure. ({message})",
  },
  STRUCTURE_SAVE_SUCCESS_TOAST: {
    ko: "TMF 구조 저장에 성공했습니다.",
    en: "The TMF structure saved successfully.",
  },
  AUDIT_TRAIL_LOAD_FAIL_TOAST: {
    ko: "Audit Trail 데이터 불러오기에 실패했습니다. ({message})",
    en: "Failed to load Audit Trail data. ({message})",
  },
  WORKFLOW_HISTORY_LOAD_FAIL_TOAST: {
    ko: "Workflow History 데이터 불러오기에 실패했습니다. ({message})",
    en: "Failed to load Workflow History data. ({message})",
  },
  DW_HISTORY_METADATA_LOAD_FAIL_TOAST: {
    ko: "Archiver Meta 데이터 불러오기에 실패했습니다. ({message})",
    en: "Failed to load Archiver Meta data. ({message})",
  },
  DW_HISTORY_LOAD_FAIL_TOAST: {
    ko: "Archiver 데이터 불러오기에 실패했습니다. ({message})",
    en: "Failed to load Archiver data. ({message})",
  },
  DW_HISTORY_CREATE_SUCCESS_TOAST: {
    ko: "Archiver 생성 요청에 성공했습니다.",
    en: "The Archiver created successfully.",
  },
  DW_HISTORY_CREATE_FAIL_TOAST: {
    ko: "Archiver 생성 요청에 실패했습니다. ({message})",
    en: "Failed to create the Archiver. ({message})",
  },
  DW_HISTORY_DOWNLOAD_SUCCESS_TOAST: {
    ko: "Archiver 다운로드에 성공했습니다.",
    en: "The Archiver downloaded successfully.",
  },
  DW_HISTORY_DOWNLOAD_FAIL_TOAST: {
    ko: "Archiver 다운로드에 실패했습니다. ({message})",
    en: "Failed to download the Archiver. ({message})",
  },
  PDF_RETRY_CONVERT_REQUEST_SUCCESS_TOAST: {
    ko: "PDF 변환 재시도 요청에 성공했습니다.",
    en: "You have requested to retry the PDF conversion successfully.",
  },
  PDF_RETRY_CONVERT_REQUEST_FAIL_TOAST: {
    ko: "PDF 변환 재시도 요청에 실패했습니다. ({message})",
    en: "Failed to request to retry the PDF conversion. ({message})",
  },

  // TOOLTIP
  DOCUMENT_INVALID_TOOLTIP_TITLE: {
    ko: "유효하지 않은 문서입니다. 정보를 수정해 주세요.",
    en: "Invalid document. Please change the information.",
  },
};

MvnCodeInitiate(TMF_STUDY_ADMIN_LANG);
