import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const SAFETY_COMMON_LANG: {
  SAFETY_COMMON_PAGE_MOVEMENT_DETECTION_ALERT_MESSAGE: MvnCodeFE;
  SAFETY_COMMON_AUDIT_TRAIL_SYSTEM_ADMIN_ACTANT: MvnCodeFE;
  SAFETY_COMMON_SELECT: MvnCodeFE;
  SAFETY_COMMON_DATE_FORMAT: MvnCodeFE;
  SAFETY_COMMON_INPUT_PLACEHOLDER: MvnCodeFE;
  SAFETY_COMMON_MAXIMUM_CHARACTERS: MvnCodeFE;
  SAFETY_COMMON_DUPLICATED_NAME: MvnCodeFE;
  SAFETY_COMMON_ALERT_SELECT_SINGLE_ITEM: MvnCodeFE;
  SAFETY_COMMON_GO_TO_PAGE: MvnCodeFE;
  SAFETY_COMMON_CREATE: MvnCodeFE;
  SAFETY_COMMON_UPDATE: MvnCodeFE;
  SAFETY_COMMON_CANCEL: MvnCodeFE;
  SAFETY_COMMON_DELETE: MvnCodeFE;
  SAFETY_COMMON_OK: MvnCodeFE;
  SAFETY_COMMON_SELECTED_FOLDER_MISSING: MvnCodeFE;
  SAFETY_COMMON_CONTENT_MISSING: MvnCodeFE;
  SAFETY_COMMON_NO_CHANGES: MvnCodeFE;
  SAFETY_COMMON_UPDATE_NOTIFICATION_BAR_DESCRIPTION: MvnCodeFE;
  SAFETY_COMMON_UPDATE_NOTIFICATION_DIALOG_TITLE: MvnCodeFE;
  SAFETY_COMMON_UPDATE_NOTIFICATION_DIALOG_DESCRIPTION: MvnCodeFE;
  SAFETY_COMMON_ITEM_MOVE_SUCC: MvnCodeFE;
  SAFETY_COMMON_ITEM_MOVE_FAIL: MvnCodeFE;
  SAFETY_COMMON_ACCESS_DENIED_MENU_TITLE: MvnCodeFE;
  SAFETY_COMMON_ACCESS_DENIED_MENU_DESCRIPTION: MvnCodeFE;
  SAFETY_COMMON_SYSTEM_MANUAL_TOOLTIP_CONTENT: MvnCodeFE;
} = {
  SAFETY_COMMON_PAGE_MOVEMENT_DETECTION_ALERT_MESSAGE: {
    ko: "저장되지 않은 자료가 있습니다. 페이지를 이동 하시겠습니까?",
    en: "You have unsaved data. Do you want to move the page?",
  },
  SAFETY_COMMON_AUDIT_TRAIL_SYSTEM_ADMIN_ACTANT: {
    ko: "System Admin",
    en: "System Admin",
  },
  SAFETY_COMMON_SELECT: {
    ko: "선택",
    en: "Select",
  },
  SAFETY_COMMON_DATE_FORMAT: {
    ko: "년(delimiter)월(delimiter)일",
    en: "YYYY(delimiter)MM(delimiter)DD",
  },
  SAFETY_COMMON_INPUT_PLACEHOLDER: {
    ko: "내용 입력",
    en: "Type here",
  },
  SAFETY_COMMON_MAXIMUM_CHARACTERS: {
    ko: "최대 글자수 {maxLength}자 ({count}/{maxLength})",
    en: "Maximum {maxLength} characters ({count}/{maxLength})",
  },
  SAFETY_COMMON_DUPLICATED_NAME: {
    ko: "이미 존재하는 이름입니다.",
    en: "The name already exists.",
  },
  SAFETY_COMMON_ALERT_SELECT_SINGLE_ITEM: {
    ko: "1개의 {item}을 선택해주세요.",
    en: "Please select a single {item}.",
  },
  SAFETY_COMMON_GO_TO_PAGE: {
    ko: "바로가기",
    en: "Go to Page",
  },
  SAFETY_COMMON_CREATE: {
    ko: "생성",
    en: "Create",
  },
  SAFETY_COMMON_UPDATE: {
    ko: "업데이트",
    en: "Update",
  },
  SAFETY_COMMON_CANCEL: {
    ko: "취소",
    en: "Cancel",
  },
  SAFETY_COMMON_DELETE: {
    ko: "삭제",
    en: "Delete",
  },
  SAFETY_COMMON_OK: {
    ko: "확인",
    en: "OK",
  },
  SAFETY_COMMON_SELECTED_FOLDER_MISSING: {
    ko: "선택된 폴더가 없습니다.",
    en: "No folder selected.",
  },
  SAFETY_COMMON_CONTENT_MISSING: {
    ko: "표시할 내용이 없습니다.",
    en: "No item to display.",
  },
  SAFETY_COMMON_NO_CHANGES: {
    ko: "변경사항이 없습니다.",
    en: "There are no changes.",
  },
  SAFETY_COMMON_UPDATE_NOTIFICATION_BAR_DESCRIPTION: {
    ko: "새 버전이 배포되었습니다. 모든 기능이 정상 작동할 수 있도록 새로고침이 필요합니다.",
    en: "A new version is available. Please refresh the page to ensure all features work properly.",
  },
  SAFETY_COMMON_UPDATE_NOTIFICATION_DIALOG_TITLE: {
    ko: "저장하지 않은 항목이 있습니다.",
    en: "There are unsaved items.",
  },
  SAFETY_COMMON_UPDATE_NOTIFICATION_DIALOG_DESCRIPTION: {
    ko: "업데이트 하시겠습니까?",
    en: "Are you sure you want to update?",
  },
  SAFETY_COMMON_ITEM_MOVE_SUCC: {
    ko: "선택된 아이템이 이동되었습니다.",
    en: "Selected item(s) have been moved.",
  },
  SAFETY_COMMON_ITEM_MOVE_FAIL: {
    ko: "아이템 이동에 실패했습니다. ({error})",
    en: "Failed to move item(s). ({error})",
  },
  SAFETY_COMMON_ACCESS_DENIED_MENU_TITLE: {
    ko: "해당 메뉴에 접근할 수 있는 권한이 없습니다.",
    en: "You don't have the privilege to access this menu.",
  },
  SAFETY_COMMON_ACCESS_DENIED_MENU_DESCRIPTION: {
    ko: "Workspace 관리자에게 문의하세요.",
    en: "Please contact your workspace administrator.",
  },
  SAFETY_COMMON_SYSTEM_MANUAL_TOOLTIP_CONTENT: {
    ko: "도움말",
    en: "Help",
  },
};

MvnCodeInitiate(SAFETY_COMMON_LANG);
